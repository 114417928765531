import React, { Fragment } from 'react';

import CmsModules from 'libs/wordpress/content/CmsModules';
import PropTypes from 'prop-types';

const DefaultPage = ({ data }) => {
    const { hero_content: heroContent, page_content: pageContent } = data;

    return (
        <Fragment>
            {heroContent && <CmsModules isHero data={heroContent[0]} />}
            {pageContent && <CmsModules data={pageContent} />}
        </Fragment>
    );
};

DefaultPage.propTypes = {
    data: PropTypes.shape({
        'hero_content': PropTypes.array.isRequired,
        'page_content': PropTypes.oneOfType([PropTypes.array.isRequired, PropTypes.bool]),
    }).isRequired,
};

export default DefaultPage;
